@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.carouselRecommendContainer {
  overflow: visible !important;
}

.carouselRecommendList {
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  @include mixins.mediaSmall {
    flex-direction: column;
    align-items: center;
    gap: 2rem 0;
  }
}

.carouselRecommendCard {
  width: calc(100% / 3 - 1.6rem);
  height: 100%;

  @include mixins.mediaSmall {
    width: 100%;
  }
}

.carouselRecommendSlide {
  width: calc(100% / 3 - 1.6rem) !important;
  cursor: grab;
  filter: drop-shadow(0px 4px 4px rgba(48, 48, 48, 0.2));

  @include mixins.mediaSmall {
    width: 100%;
  }
}

.carouselRecommendWafCard {
  width: 100%;
  border: 1px solid var(--bluishGrey);
  border-radius: 10px;
  overflow: hidden;
}

.carouselRecommendWafCardImage {
  width: 100%;
  height: 24rem;

  * {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
}

.carouselRecommendWafCardStack {
  padding: 2.4rem 1.6rem;

  @include mixins.mediaSmall {
    padding: 2.4rem 2rem;
  }
}

.carouselRecommendWafCardLabel {
  line-height: 1;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
}

.carouselRecommendWafCardHeading {
  font-weight: 700;
  line-height: 1.3;
  font-size: 1.8rem;
  text-align: center;
  margin-top: 0.8rem;

  @include mixins.mediaSmall {
    font-size: 2rem;
    margin-top: 1rem;
  }
}

.carouselRecommendWafCardDesc {
  line-height: 1.6;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
  margin-top: 1rem;
  min-height: 6.6rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @include mixins.mediaSmall {
    font-weight: 500;
    min-height: initial;
  }
}

.carouselRecommendWafCardLink {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  a {
    width: 16rem;
    height: 4rem;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background-color: var(--black);
    color: var(--white);
    font-weight: 600;
    font-size: 1.4rem;
    border: 2px solid var(--black);
    transition: color .25s, background-color .25s;

    @include mixins.mediaSmall {
      width: 20rem;
      height: 4.8rem;
      font-size: 1.6rem;
    }

    &:hover {
      color: var(--black);
      background-color: var(--white);
    }
  }
}
