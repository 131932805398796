@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.aboutHowToUse {
  width: 100%;
}

.aboutHowToUseHeading {
  line-height: 1;
  font-size: 2.8rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-align: center;

  @include mixins.mediaSmall {
    font-size: 2.4rem;
  }
}

.aboutHowToUseList {
  margin-top: 5.2rem;
  display: grid;
  gap: 4rem 0;

  @include mixins.mediaSmall {
    margin-top: 3.2rem;
    gap: 4rem 0;
  }
}

.aboutHowToUseListItem {
  display: flex;
  align-items: center;
  gap: 0 4rem;

  @include mixins.mediaSmall {
    flex-direction: column;
  }
}

.aboutHowToUseListItemImage {
  width: 24rem;
  height: 16rem;
  flex: none;

  @include mixins.mediaSmall {
    width: 100%;
    height: auto;
  }

  * {
    width: 100% !important;
    height: 100% !important;
  }
}

.aboutHowToUseListItemStack {
  flex: 1;

  @include mixins.mediaSmall {
    margin-top: 1.6rem;
  }
}

.aboutHowToUseListItemHeading {
  line-height: 1;
  display: flex;
  align-items: center;
  gap: 0 1.2rem;

  @include mixins.mediaSmall {
    align-items: flex-start;
  }

  small {
    background-color: var(--black);
    color: var(--white);
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 4px;
    flex: none;

    @include mixins.mediaSmall {
      font-size: 1.2rem;
      width: 3.2rem;
      height: 3.2rem;
    }
  }

  b {
    font-weight: 600;
    font-size: 2rem;

    @include mixins.mediaSmall {
      line-height: 1.4;
    }
  }
}

.aboutHowToUseListItemText {
  margin-top: 2rem;
  line-height: 1.4;
  font-size: 1.6rem;
  font-weight: 400;
  letter-spacing: 0.05em;

  @include mixins.mediaSmall {
    line-height: 1.6;
    margin-top: 1.6rem;
  }

  >a {
    color: var(--blue);
    text-decoration: underline;
  }

  >small {
    a {
      color: var(--blue);
      text-decoration: underline;
    }
  }
}

.aboutHowToUseListItemLink {
  color: var(--blue);
  line-height: 1;
  font-size: 1.4rem;
  text-decoration: underline;
  display: inline-block;
  margin-top: 1.2rem;

  @include mixins.mediaSmall {
    font-size: 1.6rem;
    margin-top: 1rem;
  }
}

.aboutHowToUseListItemSmall {
  line-height: 1;
  font-size: 1.2rem;
  font-weight: 300;
  margin-top: 1.2rem;
  display: inline-block;
  color: var(--grey);

  @include mixins.mediaSmall {
    font-size: 1.4rem;
  }
}

.aboutHowToUseButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5.2rem;

  @include mixins.mediaSmall {
    margin-top: 4.8rem;
  }
}
