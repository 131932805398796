@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.FaQList {
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  display: grid;
  gap: 2rem 0;
  @include mixins.mediaSmall {
    max-width: initial;
  }
  &Button {
    margin: 0 auto;
  }
}
