@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.FaQItem {
  width: 100%;
  border-radius: 8px;
  background-color: var(--base);
}

.FaQItemQuestion {
  line-height: 1.5;
  padding: 2.4rem;
  font-size: 2rem;
  display: flex;
  font-weight: 500;
  align-items: center;
  letter-spacing: 0.05em;
  cursor: pointer;
  position: relative;

  @include mixins.mediaSmall {
    padding: 1.6rem 3.2rem 1.6rem 1.6rem;
    font-size: 1.6rem;
    font-weight: 600;
  }

  &[data-active='true'] {
    svg {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  svg {
    display: block;
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);

    @include mixins.mediaSmall {
      right: 1.2rem;
    }
  }

  &::before {
    content: "Q";
    line-height: 1;
    font-size: 2.8rem;
    font-weight: 700;
    margin-right: 2rem;
    flex: none;

    @include mixins.mediaSmall {
      font-size: 2.4rem;
      margin-right: 1.6rem;
    }
  }
}

.FaQItemAnswer {
  line-height: 1.5;
  display: none;
  border-top: 1px solid var(--lightGrey);
  padding: 2.4rem;
  font-size: 1.6rem;
  font-weight: 400;

  @include mixins.mediaSmall {
    font-size: 1.4rem;
    line-height: 1.7;
    padding: 1.6rem;
  }

  &[data-active='true'] {
    display: block;
  }
}
