@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.aboutWahtIsPassLine {
  position: absolute;
  top: 11.5rem;
  left: -7.5rem;
  width: 100rem;

  @include mixins.mediaSmall {
    display: none;
  }

  * {
    width: 100% !important;
  }
}

.aboutWahtIsPassItem {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;

  @include mixins.mediaSmall {
    flex-direction: column-reverse;
    width: 100%;
    margin-bottom: 2.8rem;
  }

  &:nth-of-type(1) {
    @include mixins.mediaSmall {
      flex-direction: column;
    }

    .aboutWahtIsPassItemBalloon {
      top: -3.5rem;
      right: -6.5rem;

      @include mixins.mediaSmall {
        top: 0rem;
        right: -1rem;
      }
    }

    .aboutWahtIsPassItemStack {
      padding-top: 5.6rem;
      padding-left: 5.6rem;

      @include mixins.mediaSmall {
        padding: 0;
      }
    }

    .aboutWahtIsPassItemImage {
      margin-top: -2rem;

      @include mixins.mediaSmall {
        margin: 0;
        width: 31.5rem;
        padding-right: 6rem;
      }
    }
  }

  &:nth-of-type(2) {
    margin-top: 12rem;
    padding-right: 3.2rem;
    flex-direction: row-reverse;
    gap: 0 4.8rem;

    @include mixins.mediaSmall {
      padding: 0;
      margin-top: 6rem;
      flex-direction: column;
    }

    .aboutWahtIsPassItemBalloon {
      top: -6rem;
      left: -4rem;

      @include mixins.mediaSmall {
        left: -1rem;
        top: 0rem;
        z-index: 1;
      }
    }

    .aboutWahtIsPassItemImage {
      @include mixins.mediaSmall {
        margin-top: 3.6rem;
        height: 26rem;
        padding-left: 6rem;
      }
    }
  }
  &:nth-of-type(3) {
    margin-top: 12rem;
    margin-bottom: 6rem;
    padding-right: 3.2rem;
    gap: 0 4.8rem;

    @include mixins.mediaSmall {
      flex-direction: column;
      padding: 0;
      margin-top: 4rem;
      margin-bottom: 6rem;
    }
    .aboutWahtIsPassItemImage {
      @include mixins.mediaSmall {
        width: 90%;
        img {
          width: 100% !important;
          height: 240px !important;
        }
      }
    }
  }
}

.aboutWahtIsPassItemBalloon {
  width: 12rem;
  height: 12rem;
  position: absolute;

  * {
    width: 100% !important;
    height: 100% !important;
  }

  @include mixins.mediaSmall {
    // display: none;
    width: 10rem;
    height: 10rem;
  }
}

.aboutWahtIsPassItemStack {
  flex: 1;
}

.aboutWahtIsPassItemHeading {
  line-height: 1.4;
  font-weight: 600;
  font-size: 3.2rem;
  letter-spacing: 0.05em;

  em {
    color: var(--blue);
    font-weight: bold;
    font-style: normal;
  }

  @include mixins.mediaSmall {
    font-size: 2.4rem;
    margin-top: 2rem;
    text-align: center;
  }
}

.aboutWahtIsPassItemSubHeading {
  display: flex;
  align-items: center;
  margin-bottom: 1.2rem;
  em {
    background: var(--orange);
    color: var(--white);
    font-size: 2rem;
    font-style: normal;
    padding: 0.4rem 0.6rem;
    border-radius: 0.2rem;
    margin-right: 0.4rem;
    line-height: 1;
    margin-right: 1rem;
  }
  p {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 1.8;
    border-top: 2px dashed var(--blue);
    border-bottom: 2px dashed var(--blue);
    color: var(--blue);
  }

  @include mixins.mediaSmall {
    justify-content: center;
  }
}

.aboutWahtIsPassItemText {
  font-size: 1.6rem;
  line-height: 1.8;
  font-weight: 400;
  margin-top: 1.6rem;
}

.aboutWahtIsPassItemButton {
  margin-top: 3.2rem;
  display: flex;
  align-items: center;
  gap: 0 1.2rem;

  @include mixins.mediaSmall {
    flex-direction: column;
    gap: 1.6rem 0;
  }
}

.aboutWahtIsPassItemImage {
  width: 51rem;
  height: 42rem;
  flex: none;
  pointer-events: none;
  filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.1));

  @include mixins.mediaSmall {
    width: 100%;
    height: auto;
  }

  * {
    width: 100% !important;
    height: 100% !important;
  }
  &.is_noshadow {
    filter: none;
    @include mixins.mediaSmall {
      width: 72%;
      height: auto;
    }
  }
}
