@use 'sass:math';
@use 'src/assets/styles/mixins.scss';

.sboutServiceCarouselContainer {
  overflow: visible;
}

.sboutServiceCarouselSlide {
  position: relative;
  width: calc(100% / 3 - 1.6rem) !important;
  overflow: hidden;
  border-radius: 8px;

  @include mixins.mediaSmall {
    width: 100% !important;
  }

  article {
    width: 100%;
  }
}
